import React from "react";
import { AppBar, Toolbar, Typography, IconButton, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink, useNavigate  } from "react-router-dom";

const MatterHeader = ({ title, onPrevClick, onNextClick }) => {

  const navigate = useNavigate();

  return (
    <AppBar position="static" sx={{ backgroundColor: "#333", height: 50 }}>
      <Box
        sx={{
          height: "100%",
          minHeight: "auto",
          padding: "0 8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Toolbar sx={{ width: "100%" }}>
          {/* Spacer to push the title to the center */}
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "start" }}>
            <Typography
              variant="body2"
              component="div"
              sx={{ fontSize: 14, lineHeight: 1.5 }}
            >
              {title}
            </Typography>
          </Box>

          {/* Next Icon and Save Icon on the right side */}
          <Box sx={{ display: "flex", alignItems: "center" }}>

            {/* <IconButton
              edge="end"
              color="inherit"
              aria-label="save"
              sx={{ ml: 1, fontSize: "small" }}
              onClick={handleSubmit}
            >
              <SaveIcon sx={{ fontSize: 20 }} />
              <Typography
                variant="body2"
                sx={{ ml: 1, fontSize: 12, lineHeight: 1.5 }}
              >
                Save
              </Typography>
            </IconButton> */}

            <IconButton
              edge="end"
              color="inherit"
              aria-label="save"
              sx={{ ml: 1, fontSize: "small" }}
              onClick={(e)=>{navigate('/dashboard')}}
            >
              <CloseIcon sx={{ fontSize: 20 }} />
              <Typography
                variant="body2"
                sx={{ ml: 1, fontSize: 12, lineHeight: 1.5 }}
              >
                Close
              </Typography>
            </IconButton>

          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default MatterHeader;
