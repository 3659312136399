import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import Sidebar from "../components/Sidebar";
import MatterHeader from "./components/MatterHeader";
import { useSnackbar } from "../components/SnackbarContext";
import { useNavigate, useParams } from "react-router-dom";
import { post, get } from "../helpers/apiService"; // Import the fetch functions
import CircularProgress from "@mui/material/CircularProgress";
import LoadingIcon from "../components/LoadingIcon";

const AddMatterConfig = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    number: "",
    name: "",
    client: "",
    status: "",
    numberError: "",
    nameError: "",
    clientError: "",
    statusError: "",
  });

  const [isLoading, setLoading] = useState(false);

  const [pageLoading, setPageLoading] = useState(false);

  const statuses = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "pending", label: "Pending" },
  ];

  // check type is edit or a
  useEffect(() => {
    // fetech matter
    if (id) {
      fetchMatterDetail(); // fetch matter data
    }
  }, [id]);

  const fetchMatterDetail = () => {
    // check if loading already enable
    if (pageLoading) {
      return false;
    }

    setPageLoading(true); // enable loading indicator
    get(`matter/get/${id}`)
      .then((data) => {
        setPageLoading(false); // enable loading indicato
        if (data.status) {
          if (data.matter) {
            setFormData({
              ...formData,
              number: data.matter.matter_no,
              name: data.matter.name,
              client: data.matter.client,
              status: data.matter.status,
            });
          }
        } else {
          showSnackbar(data.message, "error");
        }
      })
      .catch((error) => {
        setPageLoading(false); // enable loading indicato
        showSnackbar(error, "error");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate on input change with the updated value
    validateForm(name, value);
  };

  // Common validation function
  const validateForm = (type = "", value = "") => {
    let isValid = true;
    let errors = {
      numberError: formData.numberError,
      nameError: formData.nameError,
      clientError: formData.clientError,
      statusError: formData.statusError,
    };

    if (type === "number" || type === "submit") {
      var valueNumber = value || formData.number;
      if (!valueNumber) {
        errors.numberError = "Number is required.";
        isValid = false;
      } else {
        errors.numberError = "";
      }
    }

    if (type === "name" || type === "submit") {
      var valueName = value || formData.name; // Fallback to formData.name if value is empty
      if (!valueName) {
        errors.nameError = "Name is required.";
        isValid = false;
      } else {
        errors.nameError = "";
      }
    }

    if (type === "client" || type === "submit") {
      var valueClient = value || formData.client; // Fallback to formData.client if value is empty
      if (!valueClient) {
        errors.clientError = "Client is required.";
        isValid = false;
      } else {
        errors.clientError = "";
      }
    }

    if (type === "status" || type === "submit") {
      var valueStatus = value || formData.status; // Fallback to formData.status if value is empty
      if (!valueStatus) {
        errors.statusError = "Status is required.";
        isValid = false;
      } else {
        errors.statusError = "";
      }
    }

    setFormData((prev) => ({
      ...prev,
      isError: !isValid,
      ...errors,
    }));

    return isValid;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateForm(name); // Validate on blur to show errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm("submit")) {
      // console.log({ email: formValues.email, password: formValues.password });
      //console.log(formData);
      addNewMatter(formData);
    }
  };

  // Function to make API request
  const addNewMatter = (formData) => {
    // check if loading already enable
    if (isLoading) {
      return false;
    }

    setLoading(true); // enable loading indicator
    post("matter/add-update", { ...formData, id: id || undefined })
      .then((data) => {
        setLoading(false); // enable loading indicator
        if (data.status) {
          // Save session data
          if (id) {
            navigate(-1);
          }else{
            navigate("/dashboard");
          }
          
        }

        showSnackbar(data.message, data.status ? "success" : "error");
      })
      .catch((error) => {
        setLoading(false); // enable loading indicato
        showSnackbar(error, "error");
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 0, width: "100%" }}
      >
        {/* <Toolbar /> */}
        {/* Your main content goes here */}
        {/* <h3  style={{
          mt: {
            xs: 2, // margin-top for mobile (xs)
            sm: 0  // margin-top for larger screens
          }
        }}>Matter config detail page</h3> */}

        <div>
          <MatterHeader title={id ? "Edit matter" : "Add matter detail"} />

          {/* <MatterLinks /> */}
          {/* Rest of your application */}

          {pageLoading ? (
            <LoadingIcon />
          ) : (
            <Paper
              sx={{ padding: 2, maxWidth: 600, margin: "auto", marginTop: 4 }}
            >
              <Typography variant="h6" gutterBottom>
                {id ? "Edit matter" : "Add matter detail"}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Number"
                      name="number"
                      value={formData.number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={formData.numberError ? true : false}
                      helperText={formData.numberError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={formData.nameError ? true : false}
                      helperText={formData.nameError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Client"
                      name="client"
                      value={formData.client}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={formData.clientError ? true : false}
                      helperText={formData.clientError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Status"
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={formData.statusError ? true : false}
                      helperText={formData.statusError}
                    >
                      {statuses.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      endIcon={
                        isLoading && (
                          <CircularProgress size={20} color="inherit" />
                        )
                      }
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default AddMatterConfig;
