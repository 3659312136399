import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  MenuItem,
} from "@mui/material";
import Sidebar from "../components/Sidebar";
import MatterHeader from "./components/MatterHeader";
import MatterLinks from "./components/MatterLinks";
import { Worker, Viewer, Page } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import { ChromePicker } from "react-color";
import PaletteIcon from "@mui/icons-material/Palette";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/system";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import CustomSnackbar from "../components/CustomSnackbar";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import the icon
import RestartAltIcon from "@mui/icons-material/RestartAlt"; // Import the reset icon

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "& input": {
      height: "40px",
    },
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
    {
      WebkitAppearance: "none",
      margin: 0,
    },
});

const MatterDeopsitions = () => {
  const { id } = useParams();

  const [pageNumber, setPageNumber] = useState(1);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfName, setPdfName] = useState('');
  const [pdfVersion, setPdfVersion] = useState(0);

  const [pdfSearchType, setPdfSearchType] = useState("automatic"); // automatic, pdf page no,  manual
  const [pdfSearchPageNo, setPdfSearchPageNo] = useState("");
  const [isDisablePdfSearch, setDisablePdfSearch] = useState(false);

  const PdfSearch = [
    { value: "automatic", label: "Automatic" },
    { value: "pdfPageNo", label: "PDF Page No" },
    { value: "manual", label: "Manual" },
  ];

  //popup for selecting pdf color
  const [open, setOpen] = useState(false);
  const handleOpenColorModel = (parentIndex) => {
    setOpen(true);
    setOpenConfirmationModelData(parentIndex);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenConfirmationModelData("");
  };
  
  useEffect(() => {

    if(id){
      setPdfUrl(`/pdf/${id}.pdf`);
      setPdfName(id);
    }
}, [id,pdfUrl]); // useEffect will run whenever the `designations` array changes


  const showSnackbar = (msg, type = "success") => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(type);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const colorPickerCodeFirst = [
    "#f15d5f",
    "#fad4d3",
    "#a35f61",
    "#c8a29b",
    "#93747b",
    "#fec1cf",
    "#a8333e",
    "#fab0a8",
    "#2dbb83",
    "#c3eddf",
    "#4f754c",
    "#99a67f",
    "#394148",
    "#879da1",
    "#224369",
    "#aebbc8",
    "#4b62e3",
    "#cfd5f8",
    "#342d7e",
    "#b1b2f4",
    "#539ecd",
    "#c3e5fb",
    "#eeaa41",
    "#fbeacb",
    "#644432",
    "#fed2a8",
    "#b55728",
    "#d3c192",
    "#262939",
    "#646464",
    "#757a89",
    "#afb2bd",
  ];

  const colorCodeMiddle1 = [
    ["#ae181a", "#cd282b", "#f23930", "#e06768", "#ffc4cb"],
    ["#7f0f44", "#ba1552", "#e41d56", "#ed5787", "#f5b1c7"],
    ["#432683", "#6d2d97", "#9130a6", "#b25cc0", "#deb6df"],
    ["#2d298a", "#492f9e", "#5b35ad", "#8a69c9", "#cabbe3"],
    ["#1a2272", "#2a3693", "#2a3693", "#6e7ac4", "#bdc0e6"],
    ["#0f3e97", "#196bcc", "#198bf1", "#57abf5", "#b4d5f5"],
    ["#004d8f", "#017ccb", "#019df4", "#46bbf5", "#a8e0fa"],
  ];

  const colorCodeMiddle2 = [
    ["#005457", "#018c9c", "#00b2cd", "#44c8de", "#a7e6ef"],
    ["#024335", "#006e5d", "#018b7b", "#45ac9f", "#a9dad6"],
    ["#1b422e", "#2e8334", "#43a546", "#74bf75", "#c1e1c5"],
    ["#2b5c18", "#5c9431", "#7eb943", "#a7cd75", "#d5e9c0"],
    ["#766b19", "#a3aa2d", "#c5d636", "#d8e168", "#ecf1b9"],
    ["#f1741a", "#fab72b", "#fee73a", "#fdee6e", "#fcf6ba"],
    ["#fc6413", "#fe9522", "#feb92e", "#ffcf47", "#fee9a9"],
  ];

  const colorCodeMiddle3 = [
    ["#e24809", "#f17116", "#fe8d22", "#ffad42", "#ffdaa5"],
    ["#b72f0c", "#e23f1c", "#fe4c22", "#fe7d59", "#fdc4b4"],
    ["#352320", "#523734", "#6b4a3d", "#977b71", "#cfc4bf"],
    ["#202b2f", "#3f4e5b", "#54707e", "#8399a2", "#c9d2d4"],
    ["#000000", "#484848", "#8b8b8a", "#d3d3d3", "#fefefe"],
  ];

  // main designation value
  const [designations, setDesignations] = useState([
    { label: "", color: "#1976d2", options: [] },
  ]);
  const [openConfirmationModel, setOpenConfirmationModel] = useState(false);
  const [openConfirmationModelData, setOpenConfirmationModelData] =
    useState("");
  const [openColorModelData, setOpenColorModelData] = useState({
    parentIndex: "",
  });

  // Function to open the confirmation dialog
  const handleOpenDialog = (parentIndex) => {
    setOpenConfirmationModel(true);
    setOpenConfirmationModelData(parentIndex);
  };

  // Function to close the confirmation dialog
  const handleCloseDialog = () => {
    setOpenConfirmationModel(false);
    setOpenConfirmationModelData("");
  };

  // add new designation row
  const addDesignations = () => {
    // Update state correctly using the setter function
    setDesignations((prevOptions) => [
      ...prevOptions,
      { label: "", color: "#1976d2", options: [] },
    ]);
  };

  // set data value in label & color
  const setData = (key, val, index) => {
    setDesignations((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = { ...updatedOptions[index], [key]: val };
      return updatedOptions;
    });
  };

  // add designations options
  const addOptions = (parentIndex) => {
    setDesignations((prevOptions) => {
      const updatedDesignations = [...prevOptions];
      updatedDesignations[parentIndex].options = [
        ...updatedDesignations[parentIndex].options,
        {
          page: "",
          fromLine: "",
          toLine: "",
          color: "#1976d2",
          isAdded: false,
        },
      ];
      return updatedDesignations;
    });
  };

  // set option value option array "designations"
  const setOptionValue = (value, key, optionIndex, parentIndex) => {
    setDesignations((prevOptions) => {
      const updatedDesignations = [...prevOptions];

      // Ensure the optionIndex is within bounds
      if (optionIndex < updatedDesignations[parentIndex].options.length) {
        // Update the page value of the specific option
        updatedDesignations[parentIndex].options[optionIndex] = {
          ...updatedDesignations[parentIndex].options[optionIndex],
          [key]: value,
        };
      }
      return updatedDesignations;
    });
  };

  // Initialize the plugins
  const zoomPluginInstance = zoomPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;
  const toolbarPluginInstance = toolbarPlugin();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // Function to handle thumbnail click
  const handleThumbnailClick = (pageIndex) => {
    setPageNumber(pageIndex + 1);
  };

  // Function to check if any option has isAdded set to true
  const hasAddedOptions = (index) => {
    return designations[index].options.some((option) => option.isAdded);
  };

  // delete deleteDesignation data
  const deleteDesignation = () => {
    setOpenConfirmationModel(false);

    // check if any option have added pdf highlights p[oints ] then remove first before deleting
    if (hasAddedOptions(openConfirmationModelData)) {
      showSnackbar(
        "Please delete highlighted pdf points before deleting this.",
        "error"
      );
      return false;
    }

    setDesignations((prevOptions) => {
      // Ensure the parentIndex is within bounds
      if (
        openConfirmationModelData >= 0 &&
        openConfirmationModelData < prevOptions.length
      ) {
        return prevOptions.filter(
          (_, index) => index !== openConfirmationModelData
        );
      }
      return prevOptions; // Return the unchanged state if index is out of bounds
    });
  };

  // delete designaiton option row
  const deleteDesignationValue = (index, parentIndex) => {
    const rowData = getOptionValue(parentIndex, index);

    if (!rowData) {
      return false;
    }

    // data is added in pdf then delete this from api
    if (rowData.isAdded) {
      applyColorPdf(rowData, index, parentIndex, "delete"); // call api when there is no errors
    }

    setDesignations((prevOptions) => {
      // Create a copy of the previous state
      const updatedDesignations = [...prevOptions];

      // Ensure the designationIndex and optionIndex are within bounds
      if (
        parentIndex < updatedDesignations.length &&
        index < updatedDesignations[parentIndex].options.length
      ) {
        // Remove the option at optionIndex
        updatedDesignations[parentIndex].options = updatedDesignations[
          parentIndex
        ].options.filter((_, idx) => idx !== index);
      }

      // Return the updated state
      return updatedDesignations;
    });
  };

  // get option value
  const getOptionValue = (parentIndex, optionIndex) => {
    // Check if indices are within bounds
    if (
      parentIndex < designations.length &&
      optionIndex < designations[parentIndex].options.length
    ) {
      return designations[parentIndex].options[optionIndex];
    }
    return null; // Or some default value if the indices are out of bounds
  };

  // add color to designation
  const addColorDesignationValue = (index, parentIndex) => {
    const rowData = getOptionValue(parentIndex, index);

    if (!rowData) {
      return false;
    }

    //var parentRowData = designations[parentIndex];

    var checkError = false;
    if (!rowData.page || rowData.fromLine === "0") {
      showSnackbar("Please enter a page number", "error");
      checkError = true;
    } else if (!rowData.fromLine || rowData.fromLine === "0") {
      showSnackbar("Please enter a valid from line number", "error");
      checkError = true;
    } else if (!rowData.toLine || rowData.toLine === "0") {
      showSnackbar("Please enter a valid to line number", "error");
      checkError = true;
    } else if (parseInt(rowData.toLine) < parseInt(rowData.fromLine)) {
      showSnackbar(
        "To line must be greater than or equal to from line",
        "error"
      );
      checkError = true;
    }
    // else if (parseInt(rowData.page) > 50) {
    //   showSnackbar("Page no cannot be greater then 50", "error");
    //   checkError = true;
    // }

    if (!checkError) {
      var checkPdfType = handleCheckStartingPageNo(); // check all pdf assumptions
      if (checkPdfType) {
        setOptionValue(true, "isAdded", index, parentIndex);
        applyColorPdf(rowData, index, parentIndex); // call api when there is no errors
      }
    }
  };

  const applyColorPdf = (selectedRowData, index, parentIndex, type = "add") => {
    const numbersHighlight = [];
    var pageNo = parseInt(selectedRowData.page);

    // if(pdfSearchType === 'pdfPageNo' && !pdfSearchPageNo){
    // check pdf type and page no will be changed as per selection data
    if (pdfSearchType === "manual") {
      pageNo = Number(pageNo) - 1;
    } else if (pdfSearchType === "pdfPageNo") {
      pageNo = Number(pageNo) + Number(pdfSearchPageNo) - 2;
    }

    var parentRowData = designations[parentIndex];

    // Generate numbers from 3 to 7
    for (
      let i = parseInt(selectedRowData.fromLine);
      i <= parseInt(selectedRowData.toLine);
      i++
    ) {
      numbersHighlight.push(i.toString());
    }

    var pdfFileVersion = `${pdfName}.pdf`;
    if (pdfVersion) {
      var pdfFileVersion = `${pdfName}-${pdfVersion}.pdf`;
    }

    var outputPath = `${pdfName}-${pdfVersion + 1}.pdf`;

    fetch("https://questaapi.onrender.com/highlight", {
      // Replace with your actual API endpoint
      //http://127.0.0.1:5000
      //https://questaapi.onrender.com
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pdf_path: pdfFileVersion,
        output_path: outputPath,
        numbers_to_highlight: numbersHighlight,
        // pages_to_highlight: [pageNo],
        pages_to_highlight: pageNo,
        pdf_search_type: pdfSearchType,
        color: parentRowData.color,
        opacity: type == "add" ? 1 : 0,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          showSnackbar("Network response was not ok", "error");
        }
        return response.json();
      })
      .then((result) => {
        if (result.status == "true") {
          setPdfVersion((prevVersion) => prevVersion + 1); // increase pdf version count
          setPdfUrl(result.file_url);
        } else {
          setOptionValue(false, "isAdded", index, parentIndex);
        }

        showSnackbar(
          result.message,
          result.status == "true" ? "success" : "error"
        );
      })
      .catch((error) => {
        showSnackbar(error, "error");
      });
  };

  // const handleDownload = async () => {
  //   try {
  //     // Fetch the file data as a Blob
  //     const response = await fetch(pdfUrl);
  //     const blob = await response.blob();

  //     // Create a new URL for the Blob
  //     const url = window.URL.createObjectURL(blob);

  //     // Create a hidden link element
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = `${pdfName}.pdf`; // Specify the default filename

  //     // Append the link to the body
  //     document.body.appendChild(link);

  //     // Trigger the download by simulating a click
  //     link.click();

  //     // Clean up the URL object and remove the link
  //     window.URL.revokeObjectURL(url);
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error downloading the file:", error);
  //   }
  // };

  const handleCheckStartingPageNo = () => {
    if (pdfSearchType === "pdfPageNo" && !pdfSearchPageNo) {
      showSnackbar("Please enter starting page no.", "error");
      return false;
    }

    setDisablePdfSearch(true);

    return true;
  };

  const handleResetStartingPageNo = () => {
    const hasAnyOptionAdded = designations.some(
      (designation) =>
        designation.options &&
        designation.options.some((option) => option.isAdded)
    );

    if (hasAnyOptionAdded) {
      showSnackbar(
        "Please delete highlighted pdf points before reset.",
        "error"
      );
    } else {
      setDisablePdfSearch(false);
    }
    //
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 0, width: "100%" }}
      >
        <div>
          <MatterHeader title="Enron Test Data" />
          <MatterLinks />

          <div style={{ display: "flex" }}>
            {/* <Box
              sx={{ width: "15%", overflowY: "auto", height: "100vh" }}
              className="scrollable"
            >
              <Thumbnails onThumbnailClick={handleThumbnailClick} />
            </Box> */}
            <Box sx={{ flexGrow: 1, height: "100vh" }} className="scrollable">
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
              >
                {/* <toolbarPluginInstance.Toolbar /> */}
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[
                    // highlightPluginInstance,
                    //zoomPluginInstance,
                    // thumbnailPluginInstance,
                    //toolbarPluginInstance,

                    defaultLayoutPluginInstance,
                  ]}
                  pageIndex={pageNumber - 1}
                />
              </Worker>
            </Box>

            <Box
              sx={{
                width: "30%",
                overflowY: "auto",
                height: "100vh",
                bgcolor: "background.paper",
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mt: "15px",
                }}
              >
                <TextField
                  select
                  fullWidth
                  label="Page Read"
                  size="small"
                  disabled={isDisablePdfSearch} // Use this prop to disable the TextField
                  value={pdfSearchType}
                  onChange={(e) => setPdfSearchType(e.target.value)}
                  variant="outlined"
                >
                  {PdfSearch.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                {pdfSearchType === "pdfPageNo" && (
                  <>
                    <TextField
                      label="Starting Page"
                      variant="outlined"
                      value={pdfSearchPageNo}
                      disabled={isDisablePdfSearch} // Use this prop to disable the TextField
                      onChange={(e) => setPdfSearchPageNo(e.target.value)}
                      size="small"
                    />
                  </>
                )}

                <Tooltip title={isDisablePdfSearch ? "Reset" : "Save"}>
                  <IconButton
                    color="success"
                    onClick={
                      isDisablePdfSearch
                        ? handleResetStartingPageNo
                        : handleCheckStartingPageNo
                    }
                  >
                    {isDisablePdfSearch ? (
                      <RestartAltIcon />
                    ) : (
                      <CheckCircleIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>

              {/* designaitons */}
              {designations.map((parentRow, parentIndex) => (
                <>
                  <Box
                    key={parentIndex}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mt: "15px",
                    }}
                  >
                    <TextField
                      label="Designation label"
                      variant="outlined"
                      value={parentRow.label}
                      onChange={(e) =>
                        setData("label", e.target.value, parentIndex)
                      }
                      // sx={{
                      //   width: "88%",
                      // }}
                      size="small"
                    />
                    <TextField
                      // label="Color"
                      variant="outlined"
                      value={parentRow.color}
                      onChange={(e) =>
                        setData("color", e.target.value, parentIndex)
                      }
                      size="small"
                      sx={{
                        width: "150px",
                      }}
                      InputProps={{
                        sx: {
                          "& input": {
                            backgroundColor: parentRow.color, // Set your desired background color here
                            borderColor: parentRow.color,
                          },
                        },
                      }}
                    />
                    <IconButton
                      sx={{
                        bgcolor: "#000000dd",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        p: 0,
                        "&:hover": {
                          bgcolor: "#000000dd",
                        },
                      }}
                      onClick={() => handleOpenColorModel(parentIndex)}
                    >
                      <PaletteIcon sx={{ color: "#fff" }} />
                    </IconButton>
                  </Box>

                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: "300px", pt: "2px" }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ py: 0.5, px: 0, backgroundColor: "#f1f1f1" }}
                          >
                            #Page
                          </TableCell>
                          <TableCell
                            sx={{ py: 0.5, px: 0, backgroundColor: "#f1f1f1" }}
                          >
                            From line
                          </TableCell>
                          <TableCell
                            sx={{ py: 0.5, px: 0, backgroundColor: "#f1f1f1" }}
                          >
                            To line
                          </TableCell>
                          <TableCell
                            sx={{ py: 0.5, px: 0, backgroundColor: "#f1f1f1" }}
                          >
                            {" "}
                            <Tooltip title="Add new page">
                              <IconButton
                                onClick={() => addOptions(parentIndex)}
                                color="primary"
                              >
                                <AddIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete designation">
                              <IconButton
                                onClick={() => handleOpenDialog(parentIndex)}
                                color="error"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          {/* <TableCell
                            sx={{ py: 0.5, px: 0, backgroundColor: "#fff",width:'40px' }}
                          > */}
                          {/* <IconButton
                              onClick={() => handleOpenDialog(parentIndex)}
                              color="error"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton> */}
                          {/* </TableCell> */}
                          {/* <TableCell
                            sx={{ py: 0.5, px: 0, backgroundColor: "#fff",width:'40px' }}
                          ></TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {parentRow.options.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ py: 0, px: 0 }}
                            >
                              <CustomTextField
                                variant="outlined"
                                size="small"
                                value={row.page}
                                onChange={(e) =>
                                  setOptionValue(
                                    e.target.value,
                                    "page",
                                    index,
                                    parentIndex
                                  )
                                }
                                type="number"
                                InputProps={{
                                  style: {
                                    border: "none",
                                    height: "30px",
                                    padding: 0,
                                  },
                                  notched: false,
                                  readOnly: row.isAdded, // Make the field read-only
                                }}
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                sx={{
                                  width: "55px",
                                  padding: 0,
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ py: 0.5, px: 0 }}>
                              <CustomTextField
                                variant="outlined"
                                size="small"
                                type="number"
                                value={row.fromLine}
                                onChange={(e) =>
                                  setOptionValue(
                                    e.target.value,
                                    "fromLine",
                                    index,
                                    parentIndex
                                  )
                                }
                                InputProps={{
                                  style: { border: "none", height: "30px" },
                                  notched: false,
                                  readOnly: row.isAdded, // Make the field read-only
                                }}
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                sx={{
                                  width: "55px",
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ py: 0.5, px: 0 }}>
                              <CustomTextField
                                variant="outlined"
                                size="small"
                                type="number"
                                value={row.toLine}
                                onChange={(e) =>
                                  setOptionValue(
                                    e.target.value,
                                    "toLine",
                                    index,
                                    parentIndex
                                  )
                                }
                                InputProps={{
                                  style: { border: "none", height: "30px" },
                                  notched: false,
                                  readOnly: row.isAdded, // Make the field read-only
                                }}
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                sx={{
                                  width: "55px",
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ py: 0.5, px: 0 }}>
                              {/* <Tooltip title="Select color">
                                <IconButton
                                  sx={{
                                    bgcolor: row.color,
                                    width: 20,
                                    height: 20,
                                    borderRadius: "50%",
                                    p: 0,
                                    "&:hover": {
                                      bgcolor: row.color,
                                    },
                                    "&.Mui-disabled": {
                                      bgcolor: row.color, // Background color when disabled
                                      color: row.color, // Icon color when disabled
                                    },
                                  }}
                                  onClick={() =>
                                    handleOpenColorModel(index, parentIndex)
                                  }
                                  disabled={!row.isAdded ? false : true}
                                >
                                  <PaletteIcon sx={{ color: "#fff" }} />
                                </IconButton>
                              </Tooltip> */}

                              <Tooltip title="Save">
                                <IconButton
                                  onClick={() =>
                                    addColorDesignationValue(index, parentIndex)
                                  }
                                  sx={
                                    {
                                      // padding: "8px 2px 8px 4px",
                                    }
                                  }
                                  color="success"
                                  disabled={!row.isAdded ? false : true}
                                >
                                  <CheckIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() =>
                                    deleteDesignationValue(index, parentIndex)
                                  }
                                  color="error"
                                  sx={{
                                    padding: "8px 2px 8px 8px",
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            {/* <TableCell sx={{ py: 0.5, px: 0 }}>
                              {!row.isAdded && (
                                <IconButton
                                  onClick={() =>
                                    addColorDesignationValue(index, parentIndex)
                                  }
                                  color="success"
                                >
                                  <CheckIcon fontSize="small" />
                                </IconButton>
                              )}
                            </TableCell> */}
                            {/* <TableCell sx={{ py: 0.5, px: 0 }}>
                              <IconButton
                                onClick={() =>
                                  deleteDesignationValue(index, parentIndex)
                                }
                                color="error"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ))}

              <Button
                onClick={addDesignations}
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                size="small" // Set the size to small
              >
                Add new Designation
              </Button>
            </Box>
          </div>
        </div>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            width: "70vw", // Set width to 50% of viewport width
            // height: "72vh",
            // border: "2px solid #000",
            boxShadow: 24,
            display: "flex",
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "grid",
              width: "20%",
              gridTemplateColumns: "repeat(2, 90px)", // Two columns with fixed width
              gridAutoRows: "26px", // Set height of each row
              gap: "0px", // Gap between rows and columns
              width: "fit-content", // Adjust the container width to fit the content
            }}
          >
            {colorPickerCodeFirst.map((row, index) => (
              <Box
                key={index}
                onClick={() =>
                  // setOptionValue(
                  //   row,
                  //   "color",
                  //   openColorModelData.index,
                  //   openColorModelData.parentIndex
                  // )
                  setData("color", row, openConfirmationModelData)
                }
                sx={{
                  width: "80px",
                  height: "24px",
                  backgroundColor: row,
                  border: `1px solid ${row}`, // Optional border for better visibility
                  cursor: "pointer", // Show cursor pointer on hover
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center", // Center the icon
                }}
              >
                {designations[openConfirmationModelData] &&
                  designations[openConfirmationModelData].color === row && (
                    <CheckIcon sx={{ color: "black" }} />
                  )}
                {/* Adjust color if needed */}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              // display: "grid",
              // gridTemplateColumns: "repeat(7, 60px)", // 7 columns with fixed width
              // gridAutoRows: "24px", // Set height of each row
              // gap: "7px", // Gap between rows and columns
              width: "fit-content", // Adjust the container width to fit the content
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 60px)", // 7 columns with fixed width
                //gridAutoRows: "24px", // Set height of each row
                gap: "7px", // Gap between rows and columns
                width: "fit-content", // Adjust the container width to fit the content
              }}
            >
              {/* Loop through colorPickerCode2 and colorPickerCode3 */}
              {colorCodeMiddle1.map((rows, index) => (
                <Box
                  key={index}
                  sx={
                    {
                      // display: "flow",
                    }
                  }
                >
                  {[...rows].map((row, index) => (
                    <Box
                      key={index}
                      onClick={() =>
                        // setOptionValue(
                        //   row,
                        //   "color",
                        //   openColorModelData.index,
                        //   openColorModelData.parentIndex
                        // )
                        setData("color", row, openConfirmationModelData)
                      }
                      sx={{
                        width: "60px",
                        height: "24px",
                        backgroundColor: row,
                        border: `1px solid ${row}`, // Optional border for better visibility
                        cursor: "pointer", // Show cursor pointer on hover
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the icon
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {designations[openConfirmationModelData] &&
                        designations[openConfirmationModelData].color ===
                          row && <CheckIcon sx={{ color: "black" }} />}
                      {/* Adjust color if needed */}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 60px)", // 7 columns with fixed width
                // gridAutoRows: "24px", // Set height of each row
                gap: "7px", // Gap between rows and columns
                width: "fit-content", // Adjust the container width to fit the content
                mt: "10px",
              }}
            >
              {/* Loop through colorPickerCode2 and colorPickerCode3 */}
              {colorCodeMiddle2.map((rows, index) => (
                <Box
                  key={index}
                  sx={
                    {
                      // display: "flow",
                    }
                  }
                >
                  {[...rows].map((row, index) => (
                    <Box
                      key={index}
                      onClick={() =>
                        // setOptionValue(
                        //   row,
                        //   "color",
                        //   openColorModelData.index,
                        //   openColorModelData.parentIndex
                        // )

                        setData("color", row, openConfirmationModelData)
                      }
                      sx={{
                        width: "60px",
                        height: "24px",
                        backgroundColor: row,
                        border: `1px solid ${row}`, // Optional border for better visibility
                        cursor: "pointer", // Show cursor pointer on hover
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the icon
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {designations[openConfirmationModelData] &&
                        designations[openConfirmationModelData].color ===
                          row && <CheckIcon sx={{ color: "black" }} />}
                      {/* Adjust color if needed */}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 60px)", // 7 columns with fixed width
                // gridAutoRows: "24px", // Set height of each row
                gap: "7px", // Gap between rows and columns
                width: "fit-content", // Adjust the container width to fit the content
                mt: "10px",
              }}
            >
              {/* Loop through colorPickerCode2 and colorPickerCode3 */}
              {colorCodeMiddle3.map((rows, index) => (
                <Box
                  key={index}
                  sx={
                    {
                      // display: "flow",
                    }
                  }
                >
                  {[...rows].map((row, index) => (
                    <Box
                      key={index}
                      onClick={() =>
                        // setOptionValue(
                        //   row,
                        //   "color",
                        //   openColorModelData.index,
                        //   openColorModelData.parentIndex
                        // )
                        setData("color", row, openConfirmationModelData)
                      }
                      sx={{
                        width: "60px",
                        height: "24px",
                        backgroundColor: row,
                        border: `1px solid ${row}`, // Optional border for better visibility
                        cursor: "pointer", // Show cursor pointer on hover
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the icon
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {designations[openConfirmationModelData] &&
                        designations[openConfirmationModelData].color ===
                          row && <CheckIcon sx={{ color: "black" }} />}
                      {/* Adjust color if needed */}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              width: "40%",
            }}
          >
            <Box
              sx={{
                display: "grid",
                placeItems:
                  "center" /* Center both horizontally and vertically */,
              }}
            >
              <ChromePicker
                color={
                  designations[openConfirmationModelData] &&
                  designations[openConfirmationModelData].color
                }
                onChangeComplete={(newColor) =>
                  // setOptionValue(
                  //   newColor.hex,
                  //   "color",
                  //   openColorModelData.index,
                  //   openColorModelData.parentIndex
                  // )
                  setData("color", newColor.hex, openConfirmationModelData)
                }
                disableAlpha={true} // This will disable the opacity selector
              />
            </Box>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
              sx={{ mt: 2, ml: "10px" }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmationModel}
        onClose={handleCloseDialog}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography id="confirmation-dialog-description">
            Are you sure you want to delete this designation? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteDesignation} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MatterDeopsitions;
