import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Toolbar,
  TextField,
  MenuItem,
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import Sidebar from "../components/Sidebar";
import MatterHeader from "./components/MatterHeader";
import MatterLinks from "./components/MatterLinks";
import { useSnackbar } from "../components/SnackbarContext";
import { get } from "../helpers/apiService"; // Import the fetch functions
import LoadingIcon from "../components/LoadingIcon";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../helpers/helper";
import EditIcon from "@mui/icons-material/Edit";

const MatterDetail = () => {

  const { id } = useParams();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [matterData, setMatterData] = useState({});

  useEffect(() => {
    fetchMatterDetail(); // fetch matter data
  }, []);

  const fetchMatterDetail = () => {
    // check if loading already enable
    if (isLoading) {
      return false;
    }

    setLoading(true); // enable loading indicator
    get(`matter/get/${id}`)
      .then((data) => {
        setLoading(false); // enable loading indicato
        if (data.status) {
          setMatterData(data.matter);
        } else {
          showSnackbar(data.message, "error");
        }
      })
      .catch((error) => {
        setLoading(false); // enable loading indicato
        showSnackbar(error, "error");
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 0, width: "100%" }}
      >
        {/* <Toolbar /> */}
        {/* Your main content goes here */}
        {/* <h3  style={{
          mt: {
            xs: 2, // margin-top for mobile (xs)
            sm: 0  // margin-top for larger screens
          }
        }}>Matter config detail page</h3> */}

        <div>
          <MatterHeader
            title={capitalizeFirstLetter(matterData && matterData.name)}
          />

          <MatterLinks />
          {/* Rest of your application */}

          <Card sx={{ margin: "20px" }}>
            <CardContent>
              {isLoading ? (
                <LoadingIcon />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="h6" component="div">
                      Matter detail
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                     
                      onClick={() => {
                        navigate(`/edit-matter-config/${id}`);
                      }}
                      sx={{
                        borderRadius:0
                      }}
                    >
                      <EditIcon style={{ color: '#1e88e5' }} fontSize="small" />
                      <Typography
                        variant="button"
                        style={{
                          marginLeft: "8px",
                          fontWeight: "bold",
                          fontSize: "0.875rem",
                          textTransform:"none"
                        }}
                      >
                        Edit
                      </Typography>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="text.secondary">
                      <b>Matter number:</b>{" "}
                      {capitalizeFirstLetter(
                        matterData && matterData.matter_no
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="text.secondary">
                      <b>Matter name:</b>{" "}
                      {capitalizeFirstLetter(matterData && matterData.name)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="text.secondary">
                      <b>Client:</b>{" "}
                      {capitalizeFirstLetter(matterData && matterData.client)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="text.secondary">
                      <b>Status:</b>{" "}
                      <span
                        style={{
                          backgroundColor:
                            matterData && matterData.status === "active"
                              ? "#d1ecf1"
                              : matterData && matterData.status === "pending"
                              ? "#fff3cd"
                              : "#f8d7da",
                          color:
                            matterData && matterData.status === "active"
                              ? "#0c5460"
                              : matterData && matterData.status === "pending"
                              ? "#856404"
                              : "#721c24",
                          padding: "0.3em",
                          borderRadius: "4px",
                        }}
                      >
                        {capitalizeFirstLetter(matterData && matterData.status)}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </div>
      </Box>
    </Box>
  );
};

export default MatterDetail;
