import React, { useState, useEffect } from "react";
import { Box, Typography, Toolbar } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import TableWithPaginationAndSearch from "./components/TableWithPaginationAndSearch";
import { get, post, blobPost } from "./helpers/apiService"; // Import the fetch functions
import LoadingIcon from "./components/LoadingIcon";
import { useSnackbar } from "./components/SnackbarContext";
import ConfirmDialog from "./components/ConfirmDialog"; // Import the ConfirmDialog component

const Dashboard = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState({
    open: false,
    title: "Confirm Delete",
    description: "Are you sure you want to delete this item?",
    deleteBtn: "Delete",
    loading: false,
    type: "delete",
  });
  const [deleteIndexId, setDeleteIndexId] = useState("");

  const [matterData, setMatterData] = useState([]);

  const columns = [
    { id: "matter_no", label: "Number" },
    { id: "name", label: "Name" },
    { id: "client", label: "Client" },
    { id: "status", label: "Status" },
    { id: "last_updated_by", label: "Last updated by" },
    { id: "updated_at", label: "Last updated at" },
    // { id: "action", label: "Action" },
  ];

  useEffect(() => {
    fetchMatter(); // fetch matter data
  }, []);

  const fetchMatter = () => {
    // check if loading already enable
    if (isLoading) {
      return false;
    }

    setLoading(true); // enable loading indicator
    get("matter")
      .then((data) => {
        setLoading(false); // enable loading indicato
        if (data.status) {
          var dataArray = [];
          data.matter.forEach((item) => {
            dataArray.push({
              ...item,
              last_updated_by: item.user ? item.user.name : "",
            });
          });
          setMatterData(dataArray);
        } else {
          showSnackbar(data.message, "error");
        }
      })
      .catch((error) => {
        setLoading(false); // enable loading indicato
        showSnackbar(error, "error");
      });
  };

  // handle edit funjcytion
  const handleEdit = (id) => {
    navigate(`/edit-matter-config/${id}`);
  };

  // handle delete funjcytion
  const handleDelete = (id) => {
    setOpenConfirmationDialog({
      open: true,
      title: "Confirm Delete",
      description: "Are you sure you want to delete this item?",
      deleteBtn: "Delete",
      loading: false,
      type: "delete",
    });
    setDeleteIndexId(id);
  };

  // confirmation delete
  const handleConfirmDelete = () => {
    var postUrl = "matter/delete";
    var postType = post;
    var postData = { id: deleteIndexId };

    if (openConfirmationDialog.type === "delete") {
      postUrl = "matter/delete";
      postType = post;
      postData = { id: deleteIndexId };
    } else if (openConfirmationDialog.type === "csvdownload") {
      postUrl = "matter/download-csv";
      postType = blobPost;
      postData = {};
    } else if (openConfirmationDialog.type === "xlxsdownload") {
      postUrl = "matter/download-xlsx";
      postType = blobPost;
      postData = {};
    }

    setOpenConfirmationDialog({
      ...openConfirmationDialog,
      loading: true,
    });

    postType(postUrl, postData)
      .then((data) => {
        setOpenConfirmationDialog({
          ...openConfirmationDialog,
          loading: false,
          open: false,
        });

        if (openConfirmationDialog.type === "delete") {
          if (data.status) {
            fetchMatter(); // fetch matter data
          }
          showSnackbar(data.message, data.status ? "success" : "error");
        } else {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "matter.csv"); // Give the file a name
          document.body.appendChild(link);
          link.click();
          link.remove(); // Clean up the DOM
        }

      })
      .catch((error) => {
        //setLoading(false); // enable loading indicato
        console.error("error:", error);
        //showSnackbar(error, "error");
      });
  };

  const handleDownloadCsv = () => {
    setOpenConfirmationDialog({
      open: true,
      title: "Confirm Download",
      description: "Are you sure you want to download CSV file?",
      deleteBtn: "Download",
      loading: false,
      type: "csvdownload",
    });
  };

  const handleDownloadXlxs = () => {
    setOpenConfirmationDialog({
      open: true,
      title: "Confirm Download",
      description: "Are you sure you want to download XLSX file?",
      deleteBtn: "Download",
      loading: false,
      type: "xlxsdownload",
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3, width: "100%" }}
      >
        {/* <Toolbar /> */}
        {/* Your main content goes here */}
        <h3
          style={{
            mt: {
              xs: 2, // margin-top for mobile (xs)
              sm: 0, // margin-top for larger screens
            },
          }}
        >
          Matters
        </h3>

        {isLoading ? (
          <LoadingIcon />
        ) : (
          <TableWithPaginationAndSearch
            rows={matterData}
            columns={columns}
            handleDelete={handleDelete}
            handleDownloadCsv={handleDownloadCsv}
            handleDownloadXlxs={handleDownloadXlxs}
            handleEdit={handleEdit}
            isDownloadOption={true}
            isAddOption={true}
            viewLink={"/matter/detail/"}
          />
        )}
      </Box>

      <ConfirmDialog
        open={openConfirmationDialog.open}
        title={openConfirmationDialog.title}
        content={openConfirmationDialog.description}
        deleteTitle={openConfirmationDialog.deleteBtn}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={handleConfirmDelete}
        loading={openConfirmationDialog.loading}
      />
    </Box>
  );
};

export default Dashboard;
