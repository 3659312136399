import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, IconButton, useTheme, useMediaQuery, SwipeableDrawer, AppBar, Box, CssBaseline } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from "@mui/material/Button";
import { Link as RouterLink, useNavigate  } from "react-router-dom";
import SessionUtil from '../helpers/SessionUtil';
import { post } from "../helpers/apiService"; // Import the
import { useSnackbar } from './SnackbarContext';



const drawerWidth = 240;

const Sidebar = () => {

  const { showSnackbar } = useSnackbar();
  
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = ()=>{
    
    post("logout", {})
    .then((data) => {
     
    })
    .catch((error) => {

    });

    showSnackbar('Logged out successfully.','success');
    SessionUtil.remove('user_token');
    navigate('/')
  }

  const drawerContent = (
    <>
      <Toolbar>
        <Typography variant="h6" noWrap>
          <img src="/img/Questa-Logo.png" alt="Questa" style={{ width: '100%', height: 'auto' }} />
        </Typography>
      </Toolbar>
      <List>
        <ListItem button component={RouterLink} to="/dashboard">
          <ListItemIcon sx={{ color: 'white' }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" sx={{ color: 'white' }} />
        </ListItem>
        {/* Uncomment these if needed
        <ListItem button>
          <ListItemIcon sx={{ color: 'white' }}>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" sx={{ color: 'white' }} />
        </ListItem>
        <ListItem button>
          <ListItemIcon sx={{ color: 'white' }}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" sx={{ color: 'white' }} />
        </ListItem> */}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ p: 2 }}>
        <Button onClick={(e)=>{logout()}} variant="contained" color="secondary" startIcon={<LogoutIcon />} fullWidth>
          Logout
        </Button>
      </Box>
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {isMobile && (
        <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer - 1, backgroundColor: '#2c2c2c' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{ position: "absolute", top: 8, right: 16  }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              <img src="/img/Questa-Logo.png" alt="Questa" style={{ width: '120px', height: 'auto' }} />
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        onOpen={() => setOpen(true)}
        sx={{
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#2c2c2c', // Background color of the sidebar
          },
        }}
      >
        {drawerContent}
      </SwipeableDrawer>
      {!isMobile && (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#2c2c2c', // Background color of the sidebar
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
