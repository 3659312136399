import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Toolbar,
  Fab,
  CircularProgress,
  Paper,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import TableWithPaginationAndSearch from "../components/TableWithPaginationAndSearch";
import { get, post } from "../helpers/apiService"; // Import the fetch functions
import LoadingIcon from "../components/LoadingIcon";
import { useSnackbar } from "../components/SnackbarContext";
import ConfirmDialog from "../components/ConfirmDialog"; // Import the ConfirmDialog component
import { capitalizeFirstLetter } from "../helpers/helper";
import MatterHeader from "./components/MatterHeader";
import MatterLinks from "./components/MatterLinks";
import AddIcon from "@mui/icons-material/Add";
import SessionUtil from "../helpers/SessionUtil";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PdfVersionDialog from "./components/PdfVersionDialog";
// import * as XLSX from "xlsx";
import XLSX from 'xlsx-js-style';
import { saveAs } from "file-saver";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { jsPDF } from "jspdf";
import JSZip from "jszip";


pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const MatterDepositions = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const abortControllerRef = useRef(null);

  const [isLoading, setLoading] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteIndexId, setDeleteIndexId] = useState("");

  const [depositionData, setDepositionData] = useState([]);

  const [matterData, setMatterData] = useState({});

  // file uppload extra functionlity
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [uploads, setUploads] = useState([]);

  const [pageTexts, setPageTexts] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const [openVersionDialog, setOpenVersionDialog] = useState({
    open: false,
    depositionId: "",
  });

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const columns = [
    { id: "file_name", label: "File name" },
    { id: "file_size", label: "Size" },
    // { id: "client", label: "Client" },
    // { id: "status", label: "Status" },
    //{ id: "last_updated_by", label: "Last updated by" },
    { id: "updated_at", label: "Created at" },
    // { id: "action", label: "Action" },
  ];

  useEffect(() => {
    fetchDeposition(true); // fetch matter data
  }, [id]);

  const fetchDeposition = (loadingType) => {
    // check if loading already enable
    if (isLoading) {
      return false;
    }

    // enable loading when true
    if (loadingType) {
      setLoading(true); // enable loading indicator
    }

    // Abort any existing request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    get(`matter/deposition/${id}`, { signal: abortController.signal })
      .then((data) => {
        setLoading(false); // enable loading indicato
        if (data.status) {
          setMatterData(data.matter);

          var dataArray = [];
          data.depositions.forEach((item) => {
            dataArray.push({
              ...item,
            });
          });
          setDepositionData(dataArray);
        } else {
          showSnackbar(data.message, "error");
        }
      })
      .catch((error) => {
        setLoading(false); // enable loading indicato
        // Handle AbortError separately
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          // Convert error to string if necessary
          const errorMessage = error.message || "An unknown error occurred";
          showSnackbar(errorMessage, "error");
        }
        // showSnackbar(error, "error");
      });
  };

  // handle download file
  const handleDownloadPdf = async (rowData) => {
    //console.log(rowData)
    // check data exists
    if (rowData) {

      var pdfFileName = getFileNameWithoutExtension(rowData.file_name);
      var pdfVersion = "";
      if (rowData.deposition_pdf) {
        pdfVersion = rowData.deposition_pdf.file_version;
      }

      const zip = new JSZip();
      const xlsxFileName = await convertPdfToXlsx(rowData, zip);

      // Delay for 3 seconds (3000 milliseconds)
      await delay(2000);

      await handleDownloadPdfLatestVersion(rowData, zip, xlsxFileName);

      // Generate the ZIP file and trigger download
      zip.generateAsync({ type: "blob" }).then((content) => {
        const zipFileName = `${
          pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName
        }.zip`;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = zipFileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const convertPdfToXlsx = async (rowData, zip) => {
    var pdfFileName = getFileNameWithoutExtension(rowData.file_name);
    var pdfVersion = "";
    var data = [];
    if (rowData.deposition_pdf) {
      data = rowData.deposition_pdf.highlighted_colors;
      pdfVersion = rowData.deposition_pdf.file_version;
    }

    if (data.length === 0) {
      showSnackbar("No record found for downloading XLSX file.", "error");
      return false;
    }

    // const workbook = XLSX.utils.book_new();
    // data.forEach((item) => {
    //   const worksheetData = [["Page", "From Line", "To Line", "Color"]];
    //   item.options.forEach((opt) => {
    //     if (opt.isAdded === true) {
    //       worksheetData.push([opt.page, opt.fromLine, opt.toLine, opt.color]);
    //     }
    //   });
    //   const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    //   XLSX.utils.book_append_sheet(workbook, worksheet, item.label);
    // });

//     const workbook = XLSX.utils.book_new();
// const worksheetData = []; // Array to hold the entire worksheet data

// // Add the PDF name in the first row
// worksheetData.push(["PDF Name"]);

// // Collect all designations into a single row for the second row
// const designations = [];
// data.forEach((item) => {
//   // Assuming item.label holds the designation name
//   designations.push(item.label);
// });
// worksheetData.push(["Designations", ...designations]); // Add designations row

// // Create the worksheet and add it to the workbook
// const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
// XLSX.utils.book_append_sheet(workbook, worksheet, "Summary");

const workbook = XLSX.utils.book_new();
const worksheetData = [];

// Add the PDF name in the first row
worksheetData.push([`Deposition of ${pdfFileName}`]);

// Collect all designations into the second row
const designationsRow = [];
data.forEach((item) => {
    designationsRow.push(`${item.label?item.label:'Designations'}`);
});
worksheetData.push(designationsRow);

// Collect all options and add them in the subsequent rows
let maxOptions = 0;
const optionsColumns = data.map((item) => {
    const options = item.options
        .filter((opt) => opt.isAdded)
        .map((opt) => `${opt.page}:${opt.fromLine}-${opt.page}:${opt.toLine}`);

    maxOptions = Math.max(maxOptions, options.length);
    return options;
});

// Add rows for each option under the respective designation columns
for (let i = 0; i < maxOptions; i++) {
    const row = [];
    optionsColumns.forEach((options) => {
        row.push(options[i] || "");
    });
    worksheetData.push(row);
}

// Create the worksheet
const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

// Merge the first row cells to span the width of the designations
const mergeCell = { s: { r: 0, c: 0 }, e: { r: 0, c: designationsRow.length - 1 } };
if (!worksheet['!merges']) worksheet['!merges'] = [];
worksheet['!merges'].push(mergeCell);

// Apply basic styling to the merged cell and the header row (designations)
worksheet['A1'].s = {
    fill: { fgColor: { rgb: "d8d8d8" } }, // Gray background
    alignment: { horizontal: 'center', vertical: 'center' },
    font: { color: { rgb: "000000" }, sz: 12, bold: true }, // Black text
    border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
    },
};

designationsRow.forEach((_, index) => {
    const cellRef = XLSX.utils.encode_cell({ r: 1, c: index });
    worksheet[cellRef].s = {
        fill: { fgColor: { rgb: "d8d8d8" } }, // Gray background
        alignment: { horizontal: 'center', vertical: 'center' },
        font: { color: { rgb: "000000" }, sz: 12, bold: true }, // Black text
        border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
        },
    };
});

// Calculate column widths
const columnWidths = [];
worksheetData[1].forEach((_, colIndex) => {
    const maxLength = Math.max(
        ...worksheetData.map(row => (row[colIndex]?.toString().length || 0))
    );
    columnWidths.push({ wch: maxLength + 10 }); // Add extra space
});

//console.log(columnWidths)

// Apply column widths to the worksheet
worksheet['!cols'] = columnWidths;

// Add the worksheet to the workbook
XLSX.utils.book_append_sheet(workbook, worksheet, pdfFileName);

// // Write the workbook to a file
// XLSX.writeFile(workbook, 'summary.xlsx');
// return false;



// Export the workbook with the styles applied
//XLSX.writeFile(workbook, 'summary.xlsx');
// Export the workbook with the styles applied
//XLSX.writeFile(workbook, 'summary.xlsx');





    const xlsxFileName = `${
      pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName
    }.xlsx`;

    // Write workbook to array buffer
    const xlsxArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert array buffer to Blob
    const xlsxBlob = new Blob([xlsxArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Add the XLSX file to the ZIP
    zip.file(xlsxFileName, xlsxBlob);

    return xlsxFileName;
  };

  const handleDownloadPdfLatestVersion = async (rowData, zip, xlsxFileName) => {
    var pdfFile = rowData.file_path;
    var pdfFileName = rowData.file_name;
    var pdfVersion = "";
    if (rowData.deposition_pdf) {
      pdfFile = rowData.deposition_pdf.file_path;
      pdfVersion = rowData.deposition_pdf.file_version;
    }

    var pdfUrl = `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${pdfFile}`;
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();

      const pdfFileNameWithVersion = `${
        pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName
      }`;

      // Add the PDF file to the ZIP
      zip.file(pdfFileNameWithVersion, blob);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  // const handleDownloadPdfLatestVersion = async (rowData) => {

  //   var pdfFile = rowData.file_path;
  //   var pdfFileName = rowData.file_name;
  //   var pdfVersion = "";
  //   if (rowData.deposition_pdf) {
  //     pdfFile = rowData.deposition_pdf.file_path;
  //     pdfVersion = rowData.deposition_pdf.file_version;
  //   }

  //   var pdfUrl = `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${pdfFile}`;
  //   //console.log(pdfUrl)
  //   try {
  //     // Fetch the file data as a Blob
  //     const response = await fetch(pdfUrl);
  //     const blob = await response.blob();

  //     // Create a new URL for the Blob
  //     const url = window.URL.createObjectURL(blob);

  //     // Create a hidden link element
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = `${
  //       pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName
  //     }`; // Specify the default filename

  //     // Append the link to the body
  //     document.body.appendChild(link);

  //     // Trigger the download by simulating a click
  //     link.click();

  //     // Clean up the URL object and remove the link
  //     window.URL.revokeObjectURL(url);
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error downloading the file:", error);
  //   }
  // }

  // const convertPdfToXlsx = async (rowData)  => {

  // var pdfFileName = getFileNameWithoutExtension(rowData.file_name);
  // var pdfVersion = "";
  // var data = [];
  // if (rowData.deposition_pdf) {
  //   data = rowData.deposition_pdf.highlighted_colors;
  //   pdfVersion = rowData.deposition_pdf.file_version;
  // }

  // // check pdf file has some date for highlighting
  // if(data.length === 0){

  //   showSnackbar("No record found for downloading XLSX file.", "error");
  //   return false;
  // }
  // // Create a new workbook
  // const workbook = XLSX.utils.book_new();

  // // Loop through each data array to create a sheet for each object
  // data.forEach((item) => {
  //   const worksheetData = [
  //     ["Page", "From Line", "To Line", "Color"], // Headers
  //   ];

  //   // Add rows for each option in the current item
  //   item.options.forEach((opt) => {
  //     if(opt.isAdded === true){
  //         worksheetData.push([
  //           opt.page,
  //           opt.fromLine,
  //           opt.toLine,
  //           opt.color,
  //         // opt.isAdded,
  //         ]);
  //     }

  //   });

  //   // Create a worksheet from the array
  //   const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  //   // Append the worksheet to the workbook with the label as the sheet name
  //   XLSX.utils.book_append_sheet(workbook, worksheet, item.label);
  // });

  // // Generate the file and trigger a download
  // XLSX.writeFile(workbook, `${pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName}.xlsx`);
  // }

  const getFileNameWithoutExtension = (filename) => {
    return filename.split(".").slice(0, -1).join(".");
  };

  // DOWNLOAD MINI PDF VERISON
  const miniPdfToXlsx = async (rowData) => {
    var pdfFile = rowData.file_path;
    var pdfFileName = rowData.file_name;
    var pdfVersion = "";
    if (rowData.deposition_pdf) {
      pdfFile = rowData.deposition_pdf.file_path;
      pdfVersion = rowData.deposition_pdf.file_version;
    }

    var pdfUrl = `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${pdfFile}`;

    fetch(`${process.env.REACT_APP_PYTHON_LOCAL_URL}extract-highlighted-text`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pdf_path: pdfUrl,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          showSnackbar("Network response was not ok", "error");
        }
        return response.json();
      })
      .then(async (result) => {
        if (result.status == "true") {
          // check if any highlighted records are found for downloading
          if (result.data.length === 0) {
            showSnackbar("No highlited records found in pdf.", "error");
            return false;
          }
          var fileNameMiniPdf = `${pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName}`;

          generatePDF(result.data,fileNameMiniPdf);
          // generatePdf('1.0', 'example.pdf');
          return false;

          const doc = new jsPDF();
          const margin = 10;
          const lineHeight = 10;
          const recordsPerPage = 25; // Total records per page
          const recordsPerSection = recordsPerPage / 4; // Records per section (4 sections per page)
        
          let currentPage = 1;
          let y = margin;
          let sectionIndex = 0;
          let recordIndex = 0;
        
          result.data.forEach((text, index) => {
            // Check if we need to add a new page
            if (recordIndex === 0 && index > 0 && (index % recordsPerPage === 0)) {
              doc.addPage();
              y = margin; // Reset Y-coordinate for new page
              sectionIndex = 0; // Reset section index for new page
            }
        
            // Calculate Y-coordinate for the current section
            const sectionY = margin + sectionIndex * (doc.internal.pageSize.height - 2 * margin) / 4;
        
            // Add text to the current section
            doc.text(`${index + 1}. ${removeFirstNumber(text)}`, margin, sectionY + (recordIndex % recordsPerSection) * lineHeight);
        
            recordIndex++;
        
            // Move to the next section if needed
            if (recordIndex % recordsPerSection === 0) {
              sectionIndex++;
            }
        
            // Check if we need a new page if all sections are filled
            if (sectionIndex >= 4) {
              doc.addPage();
              y = margin;
              sectionIndex = 0;
              recordIndex = 0; // Reset record index for the new page
            }
          });
        
          // Save the PDF
          doc.save(`${pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName}`);

          // const doc = new jsPDF();
          // let y = 10; // Initial Y-coordinate for text placement
          // const margin = 10;
          // const lineHeight = 10;
          // const pageHeight = doc.internal.pageSize.height;

          // result.data.forEach((text, index) => {
          //   if (y + lineHeight > pageHeight - margin) {
          //     doc.addPage(); // Add a new page if text exceeds page height
          //     y = margin; // Reset Y-coordinate for new page
          //   }

          //   doc.text(`${index + 1}. ${removeFirstNumber(text)}`, margin, y);
          //   // doc.text(` ${text}`, margin, y);
          //   y += lineHeight; // Move down for the next line
          // });

          // doc.save(
          //   `${pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName}`
          // );
        } else {
          showSnackbar(
            "Something went wrong. Please try again later.",
            result.status == "true" ? "success" : "error"
          );
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const generatePDF = (exportData,fileName) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const bottomMargin = 20; // Define the bottom margin

      // Example input array
      const inputArray = [
        "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", 
        "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z","1","2"
        ,"3"
        ,"4"
        ,"5"
        ,"6"
        ,"7"
        ,"8"
        ,"9"
        ,"10"
        ,"11"
        ,"12"
        ,"13"
        ,"14"

        ,"15"
        ,"16"
        ,"17"
        ,"18"

        ,"19"
        ,"20"
        ,"21"

        ,"22"
        ,"23"
        ,"24"
        ,"25"
        ,"26"
        ,"28"
        ,"29"
        ,"30"
        ,"31"

      ];
  
   // Example input array
  //  const inputArray = [
  //   "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", 
  //   "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"
  // ];

    // Example input array
  
    // Function to split the array into pairs of sub-arrays
    const createSubArrays = (arr, chunkSize) => {
      let results = [];
      let index = 0;

      while (index < arr.length) {
        // First sub-array of the current chunk
        let subArray1 = arr.slice(index, index + chunkSize);

        // Move the index to start the next sub-array from where the first one ends
        index += chunkSize;

        // Second sub-array of the current chunk (next set of elements)
        let subArray2 = arr.slice(index, index + chunkSize);

        // Add both sub-arrays as a pair to the result
        results.push([subArray1, subArray2]);

        // Move the index to the end of the second sub-array
        index += chunkSize;
      }

      return results;
    };

    // Call the function to split the array into chunks
    const chunkSize = 20;
    // const chunkedArrays = createSubArrays(inputArray, chunkSize);
    const chunkedArrays = createSubArrays(exportData, chunkSize);

    // Debug: Log the chunked arrays
    console.log(chunkedArrays);
    

  
     // return false;

    // Define table data with an array of lines for the first cell
    // const textArray = [
    //   "This is line 1 of the text.",
    //   "This is line 2 of the text.",
    //   "This is line 3 of the text.",
    //   "This is line 4 of the text.",
    //   "This is line 5 of the text.",
    //   "This is line 6 of the text.",
    //   "This is line 7 of the text.",
    //   "This is line 8 of the text.",
    //   "This is line 9 of the text.",
    //   "This is line 10 of the text.",
    //   "This is line 11 of the text.",
    //   "This is line 12 of the text.",
    //   "This is line 13 of the text.",
    //   "This is line 14 of the text.",
    //   "This is line 15 of the text.",
    //   "This is line 16 of the text.",
    //   "This is line 17 of the text.",
    //   "This is line 18 of the text.",
    //   "This is line 19 of the text.",
    //   "This is line 20 of the text.",
    //   "This is line 21 of the text.",
    //   "This is line 22 of the text.",
    //   "This is line 23 of the text.",
    //   "This is line 24 of the text.",
    //   "This is line 25 of the text."
    // ];

    // const data = [
    //   [textArray, textArray],
    //   [textArray, textArray],
    //   [textArray,""],
    // ];

    const data = chunkedArrays;

    // Define table dimensions and position
    const startX = 14;
    const startY = 20;
    const lineHeight = 4; // Adjust this value to change line height
    const cellWidth = (pageWidth - 2 * startX) / 2; // Two columns
    const cellHeight = (pageHeight - startY - bottomMargin) / 2; // Two rows, accounting for bottom margin

    const numRowsPerPage = 2; // Number of rows per page

    const drawTable = (startIndex) => {
      let currentY = startY;

      // Set font size to 8px
      doc.setFontSize(8);

      // Draw table rows
      doc.setTextColor(0, 0, 0); // Text color for table content
      for (let i = startIndex; i < Math.min(startIndex + numRowsPerPage, data.length); i++) {
        const row = data[i];
        row.forEach((cell, colIndex) => {
          const x = startX + colIndex * cellWidth;
          doc.rect(x, currentY, cellWidth, cellHeight); // Draw rectangle for cell

          if (Array.isArray(cell)) {
            // If cell contains an array of text, render each line
            let textY = currentY + 7;
            cell.forEach((line,index) => {
              if (textY < currentY + cellHeight - 2) { // Ensure text stays within cell bounds
                doc.text(`${index + 1}. ${removeFirstNumber(line)}`, x + 5, textY);
                textY += 6; // Adjust the line height
              }
            });
          } else {
            // If cell contains normal text
            doc.text(cell, x + 5, currentY + 7);
          }
        });
        currentY += cellHeight;

        // Check if we need to add a new page
        if (i + 1 < data.length && currentY + cellHeight > (pageHeight - bottomMargin)) {
          doc.addPage();
          currentY = startY;
        }
      }
    };

    let startIndex = 0;
    while (startIndex < data.length) {
      drawTable(startIndex);
      startIndex += numRowsPerPage;
    }

    // Save the PDF
    doc.save(`${fileName}`);
  };

  // Example usage
const result = {
  data: Array.from({ length: 100 }, (_, i) => `Record ${i + 1}`)
};

const generatePdfss = (pdfVersion, pdfFileName) => {
  const doc = new jsPDF();
  const margin = 10;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const colWidth = (pageWidth - 3 * margin) / 2; // Width of each column (2 columns)
  const rowHeight = (pageHeight - 3 * margin) / 2; // Height of each row (2 rows)
  const recordsPerCell = 25; // Records per cell
  const cellMargin = 5; // Padding inside the cell
  const fontSize = 10; // Font size for text
  doc.setFontSize(fontSize);

  // Generate sample data (adjust this as needed)
  const result = {
    data: Array.from({ length: 50 }, (_, i) => `Record ${i + 1}`) // Adjusted data count
  };

  let currentRecord = 0;

  // Function to draw cell borders
  const drawCellBorders = () => {
    for (let row = 0; row < 2; row++) {
      for (let col = 0; col < 2; col++) {
        const x = margin + col * (colWidth + margin);
        const y = margin + row * (rowHeight + margin);
        doc.rect(x, y, colWidth, rowHeight); // Draw border for each cell
      }
    }
  };

  // Function to add text to the specified cell
  const addTextToCell = (text, colIndex, rowIndex) => {
    const x = margin + colIndex * (colWidth + margin) + cellMargin;
    let y = margin + rowIndex * (rowHeight + margin) + cellMargin;
    const textWidth = colWidth - 2 * cellMargin; // Text width inside cell
    const maxLinesPerCell = Math.floor((rowHeight - 2 * cellMargin) / (fontSize * 1.2)); // Estimate lines per cell
    
    // Split the text into lines to fit within the cell width
    let lines = doc.splitTextToSize(text, textWidth);

    lines.forEach((line, i) => {
      if (y + fontSize > margin + rowIndex * (rowHeight + margin) + rowHeight - cellMargin) {
        // If line exceeds cell height, start a new page
        doc.addPage();
        drawCellBorders();
        y = margin + cellMargin; // Reset Y position for new page
      }
      doc.text(line, x, y);
      y += fontSize * 1.2; // Move Y position down for next line
    });
  };

  // Function to handle adding records and pagination
  const addRecords = () => {
    while (currentRecord < result.data.length) {
      // Draw initial borders for the first page
      if (doc.internal.getNumberOfPages() === 0) {
        drawCellBorders();
      }

      // Check if we need to add a new page
      if (currentRecord > 0 && (currentRecord % (recordsPerCell * 4)) === 0) {
        doc.addPage();
        drawCellBorders();
      }

      // Determine column and row positions
      const cellIndex = Math.floor(currentRecord / recordsPerCell);
      const colIndex = cellIndex % 2; // 2 columns
      const rowIndex = Math.floor(cellIndex / 2); // 2 rows

      // Add text to the correct cell
      addTextToCell(result.data[currentRecord], colIndex, rowIndex);

      currentRecord++;
    }
  };

  // Draw initial borders and add records
  addRecords();

  // Save the PDF
  doc.save(`${pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName}`);
};


  const removeFirstNumber = (str) => {
    // Replace the first occurrence of a number in the string
    var data = str.replace(/\d/, "").trim();
    console.log(data);
    return data;
  };

  // handle delete funjcytion
  const handleDelete = (id) => {
    setOpenConfirmationDialog(true);
    setDeleteIndexId(id);
  };

  // confirmation delete
  const handleConfirmDelete = () => {
    setOpenConfirmationDialog(false);

    post("matter/deposition/delete", { id: deleteIndexId })
      .then((data) => {
        //setLoading(false); // enable loading indicator
        if (data.status) {
          fetchDeposition(true); // fetch matter data
        }

        showSnackbar(data.message, data.status ? "success" : "error");
      })
      .catch((error) => {
        //setLoading(false); // enable loading indicato
        console.error("Login failed:", error);
        showSnackbar(error, "error");
      });
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    const pdfFiles = selectedFiles.filter(
      (file) => file.type === "application/pdf"
    );

    if (pdfFiles.length < selectedFiles.length) {
      // If there are any non-PDF files, show an error message or handle as needed
      showSnackbar("Only PDF files are allowed.", "error");
      return false;
    }

    const newUploads = selectedFiles.map((file, index) => ({
      id: Date.now() + index, // Unique ID
      file,
      progress: 0,
      timeRemaining: null,
      uploading: true,
    }));
    setUploads((prevUploads) => [...prevUploads, ...newUploads]);
    newUploads.forEach((upload) => uploadFile(upload));
  };

  const uploadFile = (upload) => {
    const xhr = new XMLHttpRequest();
    const startTime = Date.now();
    const token = SessionUtil.get("user_token"); // Replace with your auth token

    // Update the state to include the XMLHttpRequest instance
    setUploads((prevUploads) =>
      prevUploads.map((item) =>
        item.id === upload.id ? { ...item, xhr } : item
      )
    );

    xhr.open("POST", `${process.env.REACT_APP_API_URL}matter/deposition/add`); // Replace with your API endpoint
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        const elapsedTime = Date.now() - startTime;
        const uploadSpeed = event.loaded / elapsedTime; // bytes per millisecond
        const remainingBytes = event.total - event.loaded;
        const estimatedTimeRemaining = remainingBytes / uploadSpeed; // milliseconds

        setUploads((prevUploads) =>
          prevUploads.map((item) =>
            item.id === upload.id
              ? {
                  ...item,
                  progress: Math.round(percentComplete),
                  timeRemaining: estimatedTimeRemaining,
                }
              : item
          )
        );
      }
    };

    xhr.onload = () => {
      // console.log(xhr);
      if (xhr.status === 200) {
        // setUploads((prevUploads) =>
        //   prevUploads.map((item) =>
        //     item.id === upload.id ? { ...item, uploading: false } : item
        //   )
        // );

        const response = JSON.parse(xhr.responseText);

        setUploads((prevUploads) =>
          prevUploads.filter((item) => item.id !== upload.id)
        );

        if (response && response.status === true) {
          showSnackbar(
            `File '${upload.file.name}' uploaded successfully`,
            "success"
          );
          fetchDeposition(false); // fetch deposition with false loading
        } else if (response && response.status === false) {
          console.log(response);
          //showSnackbar(response.message, "error");
          showSnackbar(
            `Error uploading '${upload.file.name}': ${response.message}`,
            "error"
          );
        }
      } else {
        // Handle upload failure
        showSnackbar(`File '${upload.file.name}' upload failed`, "error");
        setUploads((prevUploads) =>
          prevUploads.filter((item) => item.id !== upload.id)
        );
      }
    };

    xhr.onerror = () => {
      //console.error("File upload failed");
      showSnackbar("File upload failed", "error");
      setUploads((prevUploads) =>
        prevUploads.filter((item) => item.id !== upload.id)
      );
    };

    const formData = new FormData();
    formData.append("file", upload.file);
    formData.append("matter_id", id); // Example matter ID

    xhr.send(formData);
  };

  const handleRemoveUpload = (id) => {
    //setUploads((prevUploads) => prevUploads.filter((item) => item.id !== id));
    setUploads((prevUploads) => {
      const uploadToRemove = prevUploads.find((item) => item.id === id);

      if (uploadToRemove && uploadToRemove.xhr) {
        // Abort the XMLHttpRequest if it exists
        uploadToRemove.xhr.abort();
      }

      // Remove the upload from the state
      return prevUploads.filter((item) => item.id !== id);
    });
  };

  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / 60000);
    return `${minutes}m ${seconds}s`;
  };

  // view versioing data
  const handlePdfVersioning = (selectedId) => {
    setOpenVersionDialog({ depositionId: selectedId, open: true });
  };

  const handleCloseVersionDialog = () => {
    setOpenVersionDialog({ ...openConfirmationDialog, open: false });
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 0, width: "100%" }}
      >
        {/* <Toolbar /> */}
        {/* Your main content goes here */}

        <MatterHeader
          title={capitalizeFirstLetter(matterData && matterData.name)}
        />

        <MatterLinks />

        <div
          style={{
            padding: 20,
          }}
        >
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <TableWithPaginationAndSearch
              rows={depositionData}
              columns={columns}
              handleDelete={handleDelete}
              isDownloadOption={false}
              isAddOption={false}
              handleDownloadPdf={handleDownloadPdf}
              miniPdfToXlsx={miniPdfToXlsx}
              viewLink={`/matter/${id}/depositions/`}
              handlePdfVersioning={handlePdfVersioning}
            />
          )}

          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              fileInputRef.current.click();
            }}
            style={{
              position: "fixed",
              bottom: "1px",
              right: "16px",
            }}
          >
            <AddIcon />
          </Fab>
          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            multiple
            onChange={handleFileChange}
            accept=".pdf"
          />

          {uploads.length > 0 && (
            <Box
              position="fixed"
              bottom="20px"
              right="90px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              component={Paper}
              padding="16px"
              borderRadius="8px"
              boxShadow={3}
              maxWidth="400px"
              width="100%"
            >
              <Box width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  paddingBottom="5px"
                  borderBottom="1px solid #ddd"
                >
                  <Typography variant="h6">
                    {uploads.length > 1 ? "File Uploads" : "File Upload"}
                  </Typography>
                  <IconButton onClick={toggleExpand}>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Box>
              </Box>

              {expanded && (
                <Box
                  marginTop={1}
                  maxHeight="200px" // Set the desired height
                  overflow="auto !important" // Enables vertical scrolling
                  width="100%" // Ensures the scrollable area takes the full width
                  padding="5px 7px"
                >
                  {uploads.map((upload, index) => (
                    <Paper
                      key={upload.id}
                      elevation={3}
                      style={{
                        padding: "8px 4px",
                        marginBottom: "7px",
                        position: "relative",
                        width: "100%", // Ensures each item takes full width
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          // position="relative"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            position="relative"
                          >
                            <CircularProgress
                              variant="determinate"
                              value={upload.progress}
                              size={50} // Increase size if needed
                            />
                            <Box
                              position="absolute"
                              top="50%"
                              left="50%"
                              style={{
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <Typography
                                variant="caption"
                                component="div"
                                color="textSecondary"
                              >
                                {`${upload.progress}%`}
                              </Typography>
                            </Box>
                          </Box>
                          <Box marginLeft={2}>
                            <Tooltip title={upload.file.name}>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  maxWidth: "200px", // Adjust the width to your preference
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {upload.file.name}
                              </Typography>
                            </Tooltip>
                            {upload.timeRemaining !== null && (
                              <Typography variant="body2" color="textSecondary">
                                Time remaining:{" "}
                                {formatTime(upload.timeRemaining)}
                              </Typography>
                            )}
                          </Box>
                        </Box>

                        <IconButton
                          size="small"
                          onClick={() => handleRemoveUpload(upload.id)}
                          disabled={!upload.uploading}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Paper>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </div>
      </Box>

      <PdfVersionDialog
        open={openVersionDialog.open}
        handleClose={() => handleCloseVersionDialog()}
        depositionId={openVersionDialog.depositionId}
      />

      <ConfirmDialog
        open={openConfirmationDialog}
        title="Confirm Delete"
        content="Are you sure you want to delete this item?"
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  );
};

export default MatterDepositions;
