import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import Dashboard from "./Dashboard";
import AddMatterConfig from "./matter/AddMatterConfig";
import MatterDetail from "./matter/MatterDetail";
import MatterDeopsitions from "./matter/MatterDeopsitions";
import MatterDepositions from "./matter/MatterDepositions";
import MatterDepositionsPdf from "./matter/MatterDepositionsPdf";
import Test from "./Test";
import { SnackbarProvider } from "./components/SnackbarContext";
import PrivateRoute from "./components/PrivateRoute"; // Import the guard

function App() {
  return (
    <SnackbarProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/add-matter-config" element={<AddMatterConfig />} />
          <Route path="/edit-matter-config/:id" element={<AddMatterConfig />} />
          <Route path="/matter/detail/:id" element={<MatterDetail />} />
          <Route path="/matter/depositions/:id" element={<MatterDepositions />} />
          <Route path="/matter/:id/depositions/:deposition_id" element={<MatterDepositionsPdf />} />

          {/* <Route
            path="/matter/depositions/:id"
            element={<MatterDeopsitions />}
          /> */}
        </Route>

        <Route path="/test" element={<Test />} />
      </Routes>
    </SnackbarProvider>
  );
}

export default App;
