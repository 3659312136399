import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Box } from '@mui/material';
import { Link as RouterLink, useNavigate,useLocation,useParams } from "react-router-dom";

const MatterLinks = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();


  const items = [
    { id: 1, text: 'Matter Detail',linkUrl:`/matter/detail/${id}` },
    // { id: 2, text: 'Matter Data' },
    // { id: 3, text: 'Data Processing' },
    // { id: 4, text: 'Review Tasks' },
    // { id: 5, text: 'Workspace' },
    { id: 6, text: 'Depositions',linkUrl:`/matter/depositions/${id}` },
    // Add more items as needed
  ];

  const [activeItem, setActiveItem] = useState(null);
  const [activeChildItem, setActiveChildItem] = useState(null);

  const [childItem, setChildItem] = useState([]);

  const handleItemClick = (id) => {
    

    navigate(id);

    setActiveItem(id);

    var childDataArray = [];
    // checked clicked data is matter data
    if(id === 2){
        childDataArray = [ 
            { id: 21, text: 'Data Upload' },
            { id: 22, text: 'Data Import' },
        ]
        setActiveChildItem(21);
    }else if(id === 3){
        childDataArray = [ 
            { id: 31, text: 'Data Summary' },
            { id: 32, text: 'Term Hits' },
            { id: 33, text: 'Topic Hits' },
            { id: 34, text: 'Data Query' },
        ]
        setActiveChildItem(31);
    }else if(id === 4){
        childDataArray = [ 
            { id: 41, text: 'Tasks' },
            { id: 42, text: 'Documents' },
        ]
        setActiveChildItem(41);
    }else if(id === 5){
        childDataArray = [ 
            { id: 51, text: 'Imports' },
            { id: 52, text: 'Exports' },
            { id: 53, text: 'Exhibits' },
        ]
        setActiveChildItem(51);
    }else{
        setActiveChildItem(null);
    }
    setChildItem(childDataArray);

  };

   useEffect(() => {
    if (location.pathname.startsWith('/matter/detail/')) {
      //console.log(`Matched URL: ${location.pathname}`);
      setActiveItem(1);
      // Perform your actions here
    }else if (location.pathname.startsWith('/matter/depositions/')) {
      //console.log(`Matched URL: ${location.pathname}`);
      setActiveItem(6);
      // Perform your actions here
    }
  }, [location]);

  const handleChildItemClick = (id) => {
    setActiveChildItem(id);
  };

  return (
    <>
    <Box sx={{ overflowX: 'auto', borderBottom:'1px solid rgb(217, 223, 236)' }}>
      <Grid container wrap="nowrap" sx={{ width: '50%' }}>
        {items.map((item) => (
          <Grid item xs={12} sm={6} md={6} lg={6} key={item.id}>
            <Paper
              elevation={3}
              sx={{
                padding: '6px 5px',
                cursor: 'pointer',
                backgroundColor: activeItem === item.id ? 'white' : 'rgb(217, 223, 236)',
                borderBottom: activeItem === item.id ? '2px solid blue' : 'none',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: 'white',
                  borderBottom:'1px solid rgb(217, 223, 236)'
                },
                borderRadius:0
              }}
              onClick={() => handleItemClick(item.linkUrl)}
            >
              <Typography variant="body1" component="div" fontSize={14} textAlign={'center'}>
                {item.text}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>

    <Box sx={{ overflowX: 'auto', borderBottom:'1px solid rgb(217, 223, 236)' }}>
      <Grid container wrap="nowrap" sx={{ width: '50%' }}>
        {childItem.map((item) => (
          <Grid item xs={12} sm={6} md={6} lg={6} key={item.id}>
            <Paper
              elevation={3}
              sx={{
                padding: '4px 5px',
                cursor: 'pointer',
                backgroundColor: activeChildItem === item.id ? 'white' : 'rgb(217, 223, 236)',
                borderBottom: activeChildItem === item.id ? '2px solid blue' : 'none',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: 'white',
                  borderBottom:'1px solid rgb(217, 223, 236)'
                },
                borderRadius:0
              }}
              onClick={() => handleChildItemClick(item.id)}
            >
              <Typography variant="body1" component="div" fontSize={14} textAlign={'center'}>
                {item.text}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
    </>
  );
};

export default MatterLinks;
