
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import SessionUtil from '../helpers/SessionUtil'; // Assuming you're using the session utility we discussed earlier

const PrivateRoute = () => {
  const isLoggedIn = !!SessionUtil.get('user_token'); // Check if the user is logged in

  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
