import React, { useEffect, useState } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import samplePdf from './pdf/01_D.I. 311 - Volume 1 Trial Transcript 10-24-16.pdf';

const Test = () => {
  const [pdfBytes, setPdfBytes] = useState(null);

  useEffect(() => {
    const loadAndManipulatePdf = async () => {
      // Fetch the existing PDF
      const existingPdfBytes = await fetch(samplePdf).then(res => res.arrayBuffer());

      // Load a PDFDocument from the existing PDF bytes
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Manipulate the first page
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const { height } = firstPage.getSize();

      // Example text and positions to highlight
      const fromLine = 1;
      const toLine = 3;
      const fontSize = 12;
      const yOffset = height - fontSize * fromLine;
      const textWidth = 200; // Adjust based on your text length

      firstPage.drawRectangle({
        x: 50,
        y: yOffset - fontSize * (toLine - fromLine + 1),
        width: textWidth,
        height: fontSize * (toLine - fromLine + 1),
        color: rgb(1, 0.8, 0.8),
      });

      firstPage.drawText(`Page: 1, Lines: ${fromLine}-${toLine}`, {
        x: 50,
        y: yOffset + fontSize,
        size: fontSize,
        color: rgb(0, 0, 0),
      });

      // Serialize the PDFDocument to bytes
      const pdfBytes = await pdfDoc.save();
      setPdfBytes(pdfBytes);
    };

    loadAndManipulatePdf();
  }, []);

  const handleDownload = () => {
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    saveAs(blob, 'highlighted.pdf');
  };

  return (
    <div>
      {pdfBytes && <button onClick={handleDownload}>Download Highlighted PDF</button>}
    </div>
  );
};

export default Test;
