import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import LoadingIcon from "./LoadingIcon";

const ConfirmDialog = ({
  open,
  title,
  content,
  onClose,
  onConfirm,
  deleteTitle,
  loading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 12, padding: "16px", width:'450px', height:"240px" },
      }}
    >
      {loading ? (
        <LoadingIcon />
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="center">
            <WarningIcon color="warning" fontSize="large" />
          </Box>
          <DialogTitle
            style={{
              textAlign: "center",
              padding: "16px 24px 8px",
              fontWeight: "bold",
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            style={{ textAlign: "center", padding: "8px 24px 16px" }}
          >
            <Typography variant="body1" color="textSecondary">
              {content}
            </Typography>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "16px 24px",
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color="primary"
              style={{ marginRight: "8px", borderRadius: "8px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              variant="contained"
              color="secondary"
              style={{
                backgroundColor: "#d32f2f",
                color: "#fff",
                borderRadius: "8px",
              }}
            >
              {deleteTitle ? deleteTitle : "Delete"}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
