import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  IconButton,
  TableSortLabel,
  Box,
  Menu,
  Tooltip,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Visibility from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import * as XLSX from "xlsx";

const TableWithPaginationAndSearch = ({
  rows,
  columns,
  handleEdit,
  handleDelete,
  isDownloadOption,
  isAddOption,
  viewLink,
  handleDownloadPdf,
  miniPdfToXlsx,
  handlePdfVersioning,
  handleDownloadCsv,
  handleDownloadXlxs,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columns[0].id);
  const [anchorEl, setAnchorEl] = useState({});
  const [topMenuAnchorEl, setTopMenuAnchorEl] = useState(null); // New state for the top dropdown menu

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return order === "desc" ? -1 : 1;
    }
    if (b[orderBy] > a[orderBy]) {
      return order === "desc" ? 1 : -1;
    }
    return 0;
  };

  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  const sortedRows = filteredRows.sort((a, b) => sortComparator(a, b, orderBy));

  const handleClick = (event, id) => {
    setAnchorEl((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEl((prev) => ({ ...prev, [id]: null }));
  };

  const handleTopMenuClick = (event) => {
    setTopMenuAnchorEl(event.currentTarget);
  };

  const handleTopMenuClose = () => {
    setTopMenuAnchorEl(null);
  };

  const actionClick = (id, type) => {
    handleClose(id); // close open dropdown

    if (type === "edit") {
      handleEdit(id);
    } else if (type === "delete") {
      handleDelete(id);
    } else if (type === "downloadcsv") {
      // handleDownloadCsv();
      downloadCsv(filteredRows, columns); // Pass current rows and columns for CSV
    } else if (type === "downloadxlxs") {
      //handleDownloadXlxs();
      downloadXlsx(filteredRows, columns); // Pass current rows and columns for XLSX
    }
  };

  const downloadCsv = (data, columns) => {
    const csvRows = [];

    // Headers
    const headers = columns.map((column) => column.label);
    csvRows.push(headers.join(","));

    // Rows
    data.forEach((row) => {
      const values = columns.map((column) => row[column.id]);
      csvRows.push(values.join(","));
    });

    // Create a Blob and download it
    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "matter.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadXlsx = (data, columns) => {
    const wsData = [
      columns.map((col) => col.label), // Headers
      ...data.map((row) => columns.map((col) => row[col.id])), // Data
    ];

    // Create a new workbook and worksheet
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Table Data");

    // Export the file
    XLSX.writeFile(wb, "matter.xlsx");
  };

  return (
    <Box borderRadius={2} boxShadow={3} overflow="hidden">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="8px"
      >
        <Box display="flex" alignItems="center">
          <TextField
            variant="outlined"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            size="small"
            style={{ marginRight: "8px" }}
          />
          <Tooltip title="Search">
            <IconButton size="small">
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center">
          {isAddOption && (
            <Tooltip title="Add new matter">
              <IconButton
                onClick={() => {
                  navigate("/add-matter-config");
                }}
                size="small"
                color="primary"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          {isDownloadOption && (
            <>
              <Tooltip title="Download">
                <IconButton size="small" onClick={handleTopMenuClick}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={topMenuAnchorEl}
                open={Boolean(topMenuAnchorEl)}
                onClose={handleTopMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    handleTopMenuClose();
                    actionClick("", "downloadxlxs");
                  }}
                >
                  Download as Excel
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleTopMenuClose();
                    actionClick("", "downloadcsv");
                  }}
                >
                  Download as CSV
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Box>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.length > 0 ? (
              sortedRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {viewLink ? (
                          <RouterLink
                            to={`${viewLink}${row.id}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            {column.id === "updated_at"
                              ? format(new Date(row[column.id]), "dd MMMM yyyy")
                              : row[column.id]}
                          </RouterLink>
                        ) : column.id === "updated_at" ? (
                          format(new Date(row[column.id]), "dd MMMM yyyy")
                        ) : (
                          row[column.id]
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Tooltip title="Download">
                        <IconButton
                          size="small"
                          onClick={(event) => handleClick(event, row.id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl[row.id]}
                        open={Boolean(anchorEl[row.id])}
                        onClose={() => handleClose(row.id)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {viewLink && (
                          <MenuItem
                            onClick={() => navigate(`${viewLink}${row.id}`)}
                          >
                            <ListItemIcon>
                              <Visibility
                                fontSize="small"
                                style={{ color: "#1e88e5" }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="View" />
                          </MenuItem>
                        )}

                        {handleEdit && (
                          <MenuItem onClick={() => actionClick(row.id, "edit")}>
                            <ListItemIcon>
                              <EditIcon
                                fontSize="small"
                                style={{ color: "#808080" }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                          </MenuItem>
                        )}

                        {handleDownloadPdf && (
                          <MenuItem
                            onClick={() => {
                              handleClose(row.id); // close open dropdown
                              handleDownloadPdf(row);
                            }}
                          >
                            <ListItemIcon>
                              <DownloadIcon
                                fontSize="small"
                                style={{ color: "#008000" }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Download" />
                          </MenuItem>
                        )}

                        {miniPdfToXlsx && (
                          <MenuItem
                            onClick={() => {
                              handleClose(row.id); // close open dropdown
                              miniPdfToXlsx(row);
                            }}
                          >
                            <ListItemIcon>
                              <DownloadIcon
                                fontSize="small"
                                style={{ color: "#008000" }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Download Mini PDF" />
                          </MenuItem>
                        )}

                        {handlePdfVersioning && (
                          <MenuItem
                            onClick={() => {
                              handleClose(row.id); // close open dropdown
                              handlePdfVersioning(row.id);
                            }}
                          >
                            <ListItemIcon>
                              <PictureAsPdfIcon
                                fontSize="small"
                                style={{ color: "#008000" }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Versions" />
                          </MenuItem>
                        )}

                        <MenuItem onClick={() => actionClick(row.id, "delete")}>
                          <ListItemIcon>
                            <DeleteIcon
                              fontSize="small"
                              style={{ color: "#d32f2f" }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="Delete" />
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Box>
  );
};

export default TableWithPaginationAndSearch;
