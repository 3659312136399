import React, { useState, useEffect, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";

const theme = createTheme();

export default function ForgotPassword() {
  const [userId, setUserId] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle login logic here
    console.log({ userId });
  };


  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: "url(../img/bg.jpg)", // Replace with your image URL
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed", // Ensure background image stays fixed
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundImage: "none",
              background: "rgba(255, 255, 255, 0.8)", // Semi-transparent background for better readability
              padding: "20px",
              borderRadius: "8px",
              width: "100%", // Ensure it takes full width within the container
              maxWidth: "400px", // Set a max-width to prevent it from becoming too wide
            }}
          >
            <Box
              component="img"
              src="../img/Questa-icon.png"
              alt="Questa Logo"
              sx={{
                width: "100%",
                maxWidth: "50px",
                height: "auto",
                mb: 2,
              }}
            />
            <Typography component="h1" variant="h5">
              Forgot password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="user_id"
                label="User Id"
                name="userId"
                autoComplete="current-userId"
                autoFocus
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
    
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor:'#213C8D' }}
              >
                Submit
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    to='/'
                    style={{
                      fontSize: "15px", // Decrease font size
                      textTransform: "none", // Prevent uppercase transformation if needed
                      padding: "2px 8px", // Adjust padding as needed
                    }}
                  >
                    Back to Login?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
