// src/utils/session.js
import { ReactSession  } from 'react-client-session';

// Initialize session (optional)
/*Session.config({ 
  persist: true, // Set to true to persist session across page reloads
  cookie: {
    path: '/',  // Specify cookie path
    secure: false, // Set true for HTTPS
  },
});*/

ReactSession.setStoreType("localStorage");

const SessionUtil = {
  set: (key, value) => {
    ReactSession.set(key, value);
  },

  get: (key) => {
    return ReactSession.get(key);
  },

  remove: (key) => {
    ReactSession.remove(key);
  },

  clear: () => {
    ReactSession.clear();
  },
};

export default SessionUtil;
