import SessionUtil from './SessionUtil';

export const get = (url, options = {}) => {
  
    const token = SessionUtil.get('user_token') // Extract the token from options

    const controller = options.signal ? null : new AbortController();
    const signal = options.signal || controller.signal;

    return fetch(`${process.env.REACT_APP_API_URL}`+url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add the Bearer token here
        ...options.headers,
      },
      signal, // Pass the abort signal
      ...options,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      });
  };
  
  export const post = (url, body, options = {}) => {

    const token = SessionUtil.get('user_token') // Extract the token from options
    
    return fetch(`${process.env.REACT_APP_API_URL}`+url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add the Bearer token here
        ...options.headers,
      },
      body: JSON.stringify(body),
      ...options,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      });
  };

  export const blobPost = (url, body, options = {}) => {

    const token = SessionUtil.get('user_token') // Extract the token from options
    
    return fetch(`${process.env.REACT_APP_API_URL}`+url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add the Bearer token here
        ...options.headers,
      },
      body: JSON.stringify(body),
      ...options,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.blob();
      });
  };
  