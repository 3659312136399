import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "../../components/SnackbarContext";
import { get, post } from "../../helpers/apiService"; // Import the fetch functions
import LoadingIcon from "../../components/LoadingIcon";
import TableWithPaginationAndSearch from "../../components/TableWithPaginationAndSearch";
import ConfirmDialog from "../../components/ConfirmDialog"; // Import the ConfirmDialog component
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';

function PdfVersionDialog({ open, handleClose, depositionId }) {
  const abortControllerRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [pdfVersionData, setPdfVersionData] = useState([]);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteIndexId, setDeleteIndexId] = useState("");

  const columns = [
    { id: "file_name", label: "File name" },
    { id: "file_size", label: "Size" },
    // { id: "client", label: "Client" },
    // { id: "status", label: "Status" },
    //{ id: "last_updated_by", label: "Last updated by" },
    { id: "updated_at", label: "Created at" },
    // { id: "action", label: "Action" },
  ];

  useEffect(() => {
    if (depositionId) fetchDepositionPdfVersion(depositionId); //
  }, [depositionId]);

  const fetchDepositionPdfVersion = (depositionId) => {
    // check if loading already enable
    if (isLoading) {
      return false;
    }

    // Abort any existing request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setLoading(true); // enable loading indicato

    // Create a new AbortController for the new request
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    get(`matter/deposition/pdf/version/${depositionId}`, {
      signal: abortController.signal,
    })
      .then((data) => {
        setLoading(false); // enable loading indicato
        if (data.status) {
          setPdfVersionData(data.pdfData);

          var dataArray = [];
          data.pdfData.forEach((item) => {
            dataArray.push({
              ...item,
              file_name: `v${item.file_version}_${item.file_name}`,
            });
          });
          setPdfVersionData(dataArray);
        } else {
          handleClose();
          showSnackbar(data.message, "error");
        }
      })
      .catch((error) => {
        setLoading(false); // enable loading indicato
        // Handle AbortError separately
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          // Convert error to string if necessary
          const errorMessage = error.message || "An unknown error occurred";
          showSnackbar(errorMessage, "error");
        }
      });
  };

  // handle delete funjcytion
  const handleDelete = (id) => {
    setOpenConfirmationDialog(true);
    setDeleteIndexId(id);
  };

  // confirmation delete
  const handleConfirmDelete = () => {
    setOpenConfirmationDialog(false);

    post("matter/deposition/pdf/delete", { id: deleteIndexId })
      .then((data) => {
        //setLoading(false); // enable loading indicator
        if (data.status) {
          fetchDepositionPdfVersion(depositionId); // fetch matter data
        }

        showSnackbar(data.message, data.status ? "success" : "error");
      })
      .catch((error) => {
        //setLoading(false); // enable loading indicato
        console.error("error", error);
        showSnackbar(error, "error");
      });
  };

  // handle download file
  const handleDownloadPdf = async (rowData) => {
    //console.log(rowData)
    // check data exists
    if (rowData) {
      var pdfFile = rowData.file_path;
      var pdfFileNames = rowData.file_name;
      var pdfVersion = rowData.file_version;

      var pdfUrl = `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${pdfFile}`;
      //console.log(pdfUrl)
      try {
        // Fetch the file data as a Blob
        const response = await fetch(pdfUrl);
        const blob = await response.blob();

        // Create a new URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a hidden link element
        const link = document.createElement("a");
        link.href = url;
        link.download = pdfFileNames; // Specify the default filename

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the download by simulating a click
        link.click();

        // Clean up the URL object and remove the link
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={false} // Allows custom width
        PaperProps={{
          style: {
            width: "100%", // Set the width to 75%
            height: "100vh", // Set the height to 50% of the viewport height
            margin: "auto", // Center the dialog horizontally
            display: "flex",
            flexDirection: "column", // Ensure vertical alignment of content
            //padding: 2,
          },
        }}
      >
        <Tooltip title="Close">
          <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000", // Adjust color as needed
          }}
        >
          <CloseIcon />
        </IconButton>
        </Tooltip>
      
        <div style={{ flex: 1, overflowY: "auto", padding: 10 }}>
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <TableWithPaginationAndSearch
              rows={pdfVersionData}
              columns={columns}
              handleDelete={handleDelete}
              isDownloadOption={false}
              isAddOption={false}
              handleDownloadPdf={handleDownloadPdf}
            />
          )}
        </div>
      </Dialog>

      <ConfirmDialog
        open={openConfirmationDialog}
        title="Confirm Delete"
        content="Are you sure you want to delete this item?"
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
}

export default PdfVersionDialog;
