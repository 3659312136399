import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { post } from "../helpers/apiService"; // Import the fetch functions
import SessionUtil from "../helpers/SessionUtil";
import { useSnackbar } from "../components/SnackbarContext";
import CircularProgress from "@mui/material/CircularProgress";

const theme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const { showSnackbar } = useSnackbar();

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    isError: false,
    emailError: false,
    passwordError: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const [shrinkPasswordLabel, setShrinkPasswordLabel] = useState(false);

  useEffect(() => {
    const autofilledPassword = passwordRef.current?.value || "";

    if (!autofilledPassword) {
      setShrinkPasswordLabel(true);
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setShrinkPasswordLabel(false);
    setFormValues({
      ...formValues,
      [name]: value, // Dynamically update the email or password value
    });

    // Validate on input change
    validateForm(name);
  };

  // Common validation function
  const validateForm = (type = "") => {

    setShrinkPasswordLabel(false);

    let isValid = true;
    let errors = {
      emailError: formValues.emailError,
      passwordError: formValues.passwordError,
    };

    const autofilledPassword = passwordRef.current?.value || "";
    const autofilledEmail = emailRef.current?.value || "";

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (type === "email" || type === "submit") {
      if (!autofilledEmail) {
        errors.emailError = "Email is required.";
        isValid = false;
      } else if (!emailRegex.test(autofilledEmail)) {
        errors.emailError = "Invalid email address.";
        isValid = false;
      }else{
        errors.emailError = '';
      }
    }

    // Conditional password validation
    if (type === "password" || type === "submit") {
      if (!autofilledPassword) {
        errors.passwordError = "Password is required.";
        isValid = false;
      }else{
        errors.passwordError = '';
      }
    }

    setFormValues((prev) => ({
      ...prev,
      isError: !isValid,
      ...errors,
    }));

    return isValid;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateForm(name); // Validate on blur to show errors
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm('submit')) {
      // console.log({ email: formValues.email, password: formValues.password });

      fetchLogin(formValues.email, formValues.password);
    }
  };

  // Function to make API request
  const fetchLogin = (email, password) => {
    // check if loading already enable
    if (isLoading) {
      return false;
    }

    setLoading(true); // enable loading indicator
    post("login", { email, password })
      .then((data) => {
        setLoading(false); // enable loading indicator
        if (data.status) {
          // Save session data
          SessionUtil.set("user_token", data.access_token);
          navigate("/dashboard");
        }

        showSnackbar(data.message, data.status ? "success" : "error");
      })
      .catch((error) => {
        setLoading(false); // enable loading indicato
        console.error("Login failed:", error);
        showSnackbar(error, "error");
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Grid
          container
          sx={{
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Right Side: Image */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "stretch", // Ensures the image and form have the same height
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "77vh", // Fixed height for the container
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="../img/login-bg.png" // Replace with your image path
                alt="Login Background"
                style={{
                  width: "100%",
                  height: "100%", // Ensures the image fits within the container
                  //borderRadius: "8px",
                  // objectFit: "contain", // Ensures the full image is visible without cropping
                  // objectPosition: "center", // Centers the image within the container
                }}
              />
            </Box>
          </Grid>

          {/* Left Side: Login Form */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "75vh", // Make the form container height match the image
              background: "rgba(255, 255, 255, 0.9)", // Semi-transparent background
              padding: "30px",
              // borderRadius: "8px",
              boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              component="img"
              src="../img/Questa-icon.png"
              alt="Questa Logo"
              sx={{
                width: "100%",
                maxWidth: "50px",
                height: "auto",
                mb: 2,
              }}
            />
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ width: "100%", mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email_id"
                name="email"
                label="Email"
                autoComplete="current-userId"
                autoFocus
                value={formValues.email}
                onChange={handleInputChange} // Handle change
                onBlur={handleBlur}
                inputRef={emailRef}
                error={formValues.emailError ? true : false}
                helperText={formValues.emailError}
                sx={{
                  "& .MuiFormHelperText-root": {
                    ml: formValues.emailError ? "0px" : 1, // Remove left margin if there's an error
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={formValues.password}
                onChange={handleInputChange} // Handle change
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: formValues.password.length > 0 || shrinkPasswordLabel, // Shrink label if there's a value
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={passwordRef}
                error={formValues.passwordError ? true : false}
                helperText={formValues.passwordError}
                sx={{
                  "& .MuiFormHelperText-root": {
                    ml: formValues.passwordError ? "0px" : 1, // Remove left margin if there's an error
                  },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#213C8D" }}
                endIcon={
                  isLoading && <CircularProgress size={20} color="inherit" />
                }
              >
                Login
                {/* {isLoading ? 'Loading...' : 'Login'} */}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    to="forgot-password"
                    style={{
                      fontSize: "15px",
                      textTransform: "none",
                      padding: "2px 8px",
                    }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
